import React, { useState } from "react";
import axios from "axios";
import "./Header.css";

import SearchIcon from "@mui/icons-material/Search";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AppsIcon from "@mui/icons-material/Apps";
import NotificationsIcon from "@mui/icons-material/Notifications";

function Header({ onSearchResults }) {
  const [searchTerm, setSearchTerm] = useState("React.js");
  const [searchResults, setSearchResults] = useState([]);

  const changeHandler = (e) => {
    setSearchTerm(e.target.value);

  };

  const getYoutubeInfo = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?key=AIzaSyDIKBxRD7wj3EHjbtqUxgaHl5Z97eFTMoo&part=snippet&maxResults=20&q=${searchTerm}&type=video,channel`
      );
      const results = response.data.items;
      setSearchResults(results);
      onSearchResults(results); // Call the callback function to send the search results back up to the parent component
      console.log(searchResults)
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchClick = () => {
    getYoutubeInfo();
   
  };

  return (
    <div className="header">
      <div className="header-left">
       
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
          alt="youtube-logo"
          className="header-logo"
        />
      </div>
      <div className="header-input">
        <input
          type="text"
          placeholder="Search.."
          value={searchTerm}
          onChange={changeHandler}
        />
        <SearchIcon
          className="header-input-button"
          onClick={handleSearchClick}
        />
      </div>
      <div className="header-icons">
        <VideoCallIcon className="header-icon" />
        <AppsIcon className="header-icon" />
        <NotificationsIcon className="header-icon" />
      </div>
    </div>
  );
}

export default Header;




