import React from "react";
import "./RecommendedVideos.css";
import YouTubeVideo from './YoutubePlayer.js';


function RecommendedVideos({ searchResults}) {
  return (
    <div className="recommendedvideo">
      
      <div className="recommendedvideo-videos">
        {searchResults.map((result) => (
          <div key={result.id.videoId} className="search-result">
            
            
              <div className="search-bottom">
                <div className="search-result-details">
                  <h3>{result.snippet.title}</h3>
                  <p>{result.snippet.description}</p>
                
              </div>
            </div>
            <YouTubeVideo videoId={result.id.videoId} className="youtube-video"/>
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default RecommendedVideos;
