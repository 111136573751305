import './App.css';
import Header from './Header/Header';
import Sidebar from './Sidebar';
import RecommendedVideos from './RecommendedVideos';
import { useState } from 'react';

function App() {
  const [searchTerm, setSearchTerm] = useState('React.js');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  const handleSearchTerm = (term) => {
    setSearchTerm(term);
  };

  return (
    <div className="App">
      <Header onSearchResults={handleSearchResults} onSearchTerm={handleSearchTerm} />
      <div className="app-page">
        <Sidebar />
        <RecommendedVideos searchResults={searchResults} searchTerm={searchTerm} />
      </div>
    </div>
  );
}

export default App;
